@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  background-color: #161c24;
}

* {
  font-family: "Poppins", sans-serif;
}
.css-15n59i0-MuiDrawer-docked {
  background-color: transparent !important;
  border: none !important;
  width: 0 !important;
}
.css-vjgbro-MuiDrawer-docked {
  background-color: transparent !important;
  border: none !important;
  width: 160px !important;
}

.MuiTableSortLabel-root,
.MuiTableSortLabel-icon {
  color: #919eab;
}
.MuiTableSortLabel-root.Mui-active {
  color: #ffffff;
}
.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
  rotate: 180deg;
  color: #919eab;
}
.MuiTableSortLabel-root:hover {
  color: #919eab;
}

.MuiTableSortLabel-root.Mui-active:hover {
  color: #ffffff;
}
.MuiPaper-root.MuiDialog-paper {
  background-color: #212b36;
  color: #ffffff;
  border-radius: 16px;
}

.MuiTablePagination-menuItem {
  background: #212b36 !important;
}
.MuiTablePagination-menuItem:hover {
  background: #455363 !important;
}
.MuiTablePagination-menuItem:focus {
  background: #455363 !important;
}
.MuiBreadcrumbs-separator {
  color: #ffffff;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #72778b !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.MuiInputLabel-root {
  color: #72778b !important;
}

.Mui-focused.MuiInputLabel-root {
  color: #fff !important;
}

.MuiInputBase-root .MuiSvgIcon-root {
  color: #72778b;
}
.MuiInputBase-root .Mui-focused.MuiSvgIcon-root {
  color: #fff !important;
}
